<template>
    <div>
        <v-toolbar flat color="transparent">
            <v-avatar tile>
                <v-img contain :src="tenantBrand.logoUrl.dark" />
            </v-avatar>
            <v-toolbar-title>
                <span v-for="(char, i) in tenantBrand.label" :key="i" class="smallLogo">{{ char }}</span>
                <!-- <span class="smallLogo">L</span>
                <span class="smallLogo">Ø</span>
                <span class="smallLogo">G</span>
                <span class="smallLogo">L</span>
                <span class="smallLogo">I</span>
                <span class="smallLogo">V</span>
                <span class="smallLogo">E</span> -->
            </v-toolbar-title>
            <!-- <v-spacer></v-spacer>
            <span style="font-size: 12px; color: grey">
                {{ shareItem.hash }}</span> -->
        </v-toolbar>
        <div class="login">
            <v-container fill-height>
                <v-row justify="center" align="center" style="height: 90%" class="text-center">
                    <v-col cols="12" xs="12" sm="12" md="6" class="d-flex justify-center align-center">
                        <v-card flat style="width: 100%; border-radius: 20px" class="text-left" v-if="shareItem.organisation">
                            <v-card-title>
                                <v-avatar tile v-if="shareItem.organisation && shareItem.organisation.logo" class="mr-2" size="50"><v-img contain
                                        :src="shareItem.organisation.logo"></v-img></v-avatar> {{
                                            shareItem.organisation.name }}
                            </v-card-title>
                            <v-card-subtitle>
                                has shared the following documents:
                            </v-card-subtitle>
                            <v-card-title>
                                {{ shareItem.type }}
                            </v-card-title>
                            <!-- <v-card-sub-title>
                            </v-card-sub-title> -->
                            <v-card-text>
                                <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
                                <v-list>
                                    <v-list-item v-for="(document, index) in shareItem.documents" :key="index">
                                        <v-list-item-action style="width: 20px">
                                            <v-img style="height: 30px; width: 30px" :src="getIcon(document.name)">
                                            </v-img>
                                        </v-list-item-action>
                                        <v-list-item-title>
                                            {{ document.name }}
                                        </v-list-item-title>
                                        <v-list-item-action :key="documentKey">
                                            <v-btn icon :loading="downloading.includes(document.key)"
                                                @click="downloadDocument(document)">
                                                <v-icon>download</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="shareItem.documents.length == 0">
                                    <v-list-item-content class="text-center">
                                        <span style="color: grey">No documents</span>
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        documentKey: 1000,
        downloading: [],
        loading: false,
        shareItem: {}
    }),
    watch: {
        '$route.params.hash': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getShareItem(val)
                }
            }
        }
    },
    computed: {
        ...mapGetters(['tenantBrand'])
    },
    created(){
        console.log('In shared docs', this.$route.params.hash)
    },
    methods: {
        async downloadDocument(doc) {
            this.downloading.push(doc.key);
            this.documentKey++;
            if (!doc.file && !doc.fileType) {
                let file = await this.$API.downloadSharedFile({
                    key: doc.key,
                });
                doc.file = file.file;
                doc.fileType = file.fileType;
            }
            var hiddenElement = document.createElement("a");
            hiddenElement.href = doc.file;
            hiddenElement.target = "_blank";
            hiddenElement.download = doc.name;
            hiddenElement.click();
            this.downloading.splice(this.downloading.indexOf(doc.key), 1)
            this.documentKey++;
        },
        getIcon(name) {
            let type = name.split(".");
            let result = null;
            if (type.length > 0) {
                result = type.at(-1).toLowerCase();
            }
            if (result && result.toLowerCase() == "xlsx") {
                result = "xls";
            }
            return `/icons/${result}.svg`;
        },
        async getShareItem(hash) {
            this.loading = true
            this.shareItem = await this.$API.getShipmentShareItem(hash)
            this.loading = false
        }
    }
}
</script>
<style >
.login {
    flex: 1;
    height: 65vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}</style>